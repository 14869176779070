.neo__ranking__entry {
  min-height: 58px;
  display: flex;
  justify-content: stretch;
  align-items: center;
  position: relative;
  gap: 8px;
}

.neo__ranking__entry__rankHolder {
  min-width: 42px;
  flex-shrink: 0;
  flex-grow: 0;
  text-align: end;
  @include font__setThin();
}
.neo__ranking__entry__avatarHolder {
  width: 48px;
  flex-grow: 0;
  flex-shrink: 0;
}
.neo__ranking__entry__nameHolder {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  justify-content: stretch;
  align-items: center;
  overflow: hidden;
  gap: 8px;

  > span {
    cursor: pointer;
    flex-grow: 0;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  > div { // icon
    flex-shrink: 0;
  }
}
.neo__ranking__entry__scoreHolder {
  min-width: 56px;
  flex-shrink: 0;
  flex-grow: 0;
  text-align: end;
  position: relative;  
  @include font__setStrong();
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 1px;
    height: 28px;
    background: var(--theme__dividerDarkReduced);
  }
}

.neo__ranking__entry--own {
  &::before {
    content: "";
    width: 8px;
    background: var(--theme__typeHeadline);
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }
}

.neo__ranking__entriesHolder {
  > * {
    border-bottom: 1px solid var(--theme__dividerDarkReduced);
    &:last-child { border-bottom: none; }
  }
}

.neo__ranking__loadingPlaceholder {
  margin-top: 2em;
}
