.neo__tinyPagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.neo__tinyPagination__currentPage {
  @include font__setBlack();
  color: var(--theme__typeBodie);
}

.neo__tinyPagination__icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: center / contain no-repeat;
  @include themedBg("icon__arrowDown")
}
span.neo__tinyPagination__icon { opacity: .25; }
a.neo__tinyPagination__icon { cursor: pointer; }

.neo__tinyPagination__icon--prev {
  transform: rotate(90deg);
}
.neo__tinyPagination__icon--next {
  transform: rotate(-90deg);
}
