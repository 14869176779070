@mixin neo__avatar__sizing() {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 20px;
  position: relative;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
}

.neo__usernameMax2lines {
  display: -webkit-box; 
  -webkit-box-orient: vertical; 
  -webkit-line-clamp: 2; 
  overflow: hidden; 
  text-overflow: ellipsis;  
}

.neo__buddy__avatar {
  background: var(--theme__backgroundCardHard);
  @include neo__avatar__sizing();
  > * {
    width: 100%;
    height: 100%;
  }
}

.neo__buddy__avatar__placeholder {
  background: var(--theme__backgroundAboveCard);
  @include neo__avatar__sizing();
}

.neo__buddy__avatar__addIcon {
  background: var(--theme__backgroundAboveCard) center / 16px no-repeat;
  @include themedBg("icon__plus");
  @include neo__avatar__sizing();
}

.neo__buddy__surround {
  width: 48px;
  height: 48px;
  overflow: hidden;
  border-radius: 27px;
  border: 2px solid transparent;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.neo__buddy__surround--active { cursor: pointer; }
.neo__buddy__surround--transparent {}
.neo__buddy__surround--nothingNew {
  @include theme__light() { border-color: $dfl__superLightGray; }
  @include theme__dark() { border-color: $dfl__softBlack; }
}
.neo__buddy__surround--newStory {
  border-color: var(--theme__okType);
}
