.neo__header {
  padding: 12px 0;
  position: relative;
  
  > img {
    display: block;
    margin: 0 auto;
    width: 80%;
    max-width: 320px;
    height: auto;
  }
}

.neo__header__statsSection {
  margin-top: -.25em;
  padding-bottom: .5em;
}

.neo__header__nickname__holder {
  display: flex;
  justify-content: center;
  align-items: center;
}

.neo__header__nickname {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  @include font__setBlack();
  font-size: .8em;
  gap: 8px;
  > span:first-child { // avatar
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    position: relative;
    > * { flex-grow: 1; flex-shrink: 1; width: 100%; height: 100%; }
  }
}

.neo__header__stats {
  margin-top: .2em;
  display: flex;
  justify-content: center;
  justify-content: center;
  font-size: .8em;
  align-items: center;
}
.neo__header__stat {
  padding: 0 8px;
  border-inline-end: 1px solid var(--theme__dividerDarkReduced);
  &:last-child { border-inline-end: none; }
  white-space: nowrap;
}
.neo__header__stat__trend {
  background: center / contain no-repeat;
  display: inline-block;
  height: 1em;
  width: 1em;
  vertical-align: bottom;
}
.neo__header__stat__trend--up {
  @include themedBg("icon__trendUp");
}
.neo__header__stat__trend--down {
  @include themedBg("icon__trendDown");
}
.neo__header__navi {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 18px;
  @include smallUp() { gap: 0 38px; }
  flex-wrap: wrap;
}

.neo__header__navi__item {
  text-align: center;
  border-bottom: 3px solid transparent;
  border-top:  3px solid transparent;
  min-height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  > span {
    @include font__setStrong();
    white-space: nowrap;
    text-transform: uppercase;
  }
}

.neo__header__navi__item--inactive {
  cursor: pointer;
  color: var(--theme__typeReduced);
}

.neo__header__navi__item--active {
  color: var(--theme__typeHeadline);
  border-bottom-color: var(--theme__typeHeadline);
}

.neo__header__closeAppIcon {
  position: absolute;
  right: 0;
  top: .5em;
}

.neo__header__closeAppIcon {
  display: inline-block;
  width: 28px;
  height: 28px;
  background: center / contain no-repeat;
  @include themedBg("icon__close");
  cursor: pointer;
}
