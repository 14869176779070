@keyframes __ani__pickerPopup__allIn {
  from  { opacity: 0; }
  to    { opacity: 1; }
}

@keyframes __ani__pickerOverlay__popupIn {
  from  { opacity: .8; transform: scale(.9); }
  to    { opacity: 1; transform: scale(1); }
}

.neo__picker__overlay {
  z-index: 99;
  animation: __ani__pickerOverlay__allIn .5s;
  animation-fill-mode: both;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  @include theme__light() {
    background: rgba(255, 255, 255, .7);
  }
  @include theme__dark() {
    background: rgba(0, 0, 0, .65);
  }
  display: flex;
  justify-content: center;
  align-items: center;
  > * {
    position: relative;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    flex-direction: column;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .3);
    background: var(--theme__backgroundCardHard);
    animation: __ani__pickerOverlay__popupIn .4s;
    width: calc(100% - 16px);
    max-width: 420px;
    height: calc(100% - 100px);
    max-height: 800px;
  }
}

.neo__picker__closeIcon__holder {
  padding: 8px;
  flex-grow: 0;
  display: flex;
  justify-content: flex-end;
}

.neo__picker__title {
  flex-grow: 0;
  padding: 12px 8px;
  text-align: center;
  @include font__setStrong();
}

.neo__picker__inner {
  flex-grow: 1;
  display: flex;
  overflow: hidden;
  justify-content: stretch;
  align-items: stretch;
  > * {
    flex-shrink: 1;
    width: 100%;
  }
}

.neo__picker__itemsHolder {
  flex-grow: 1;
  flex-shrink: 1;
  user-select: none;
}

.neo__picker__showAllActionHolder {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 68px;
  > a {
    text-align: center;
    display: inline-block;
    min-width: 140px;
    padding: 8px 12px;
    border-radius: 20px;
    background: var(--theme__backgroundAboveCard);
  }
}

.neo__picker__closeIcon {
  cursor: pointer;
  width: 28px;
  height: 28px;
  background: $dfl__bundesligaRed url("~images/icon__close--dark.png") center / 20px no-repeat;
  border-radius: 14px;
}

.neo__picker__entry {
  display: flex;
  justify-content: stretch;
  align-items: center;
  padding: 12px 8px;
  min-height: 54px;
  gap: 8px;
  border-bottom: 1px solid var(--theme__dividerDarkReduced);
  &:last-child { border-bottom: none; }
}

.neo__picker__entry--disabled {
  opacity: .25;
}

.neo__picker__entry__imageSection {
  flex-grow: 0;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    width: 28px;
    height: 28px;
    position: relative;
    border-radius: 14px;
    overflow: hidden;
    background: var(--theme__backgroundAboveCard);
    img {
      width: 100%;
      height: auto;
    }
  }
}
.neo__picker__entry__textSection {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}
.neo__picker__entry__pointsSection {
  flex-grow: 0;
  width: 40px;
  display: flex;
  justify-content: center;
  border-inline-start: 1px solid var(--theme__dividerDarkReduced);
  align-items: center;
  gap: 4px;
  flex-direction: column;
  > span { font-size: .8em; }
  > strong { @include font__setBlack(); font-size: 1.5em; }
}
.neo__picker__entry--enabled {
  cursor: pointer;
}
.neo__picker__entry--disabled {
  cursor: not-allowed;
}

.neo__picker__teamSelector {
  position: relative;
  display: flex;
  align-items: stretch;
  gap: 4px;
  flex-wrap: wrap;
  > * {
    width: calc(100% / 6 - 4px * 5 / 6);
    flex-grow: 0;
    flex-shrink: 0;
  }
}
.neo__picker__teamSelector__item {
  &, &:hover, &:active {
    color: var(--theme__typeHeadline);
    text-decoration: none;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  > span {
    font-size: .8em;
  }
  > img {
    width: 100%;
    height: auto;
    font-size: .6em; // when alt-text shown because no image
  }
}
.neo__picker__teamSelector__holder {
  padding: 4px;
  background: var(--theme__backgroundCardReduced);
  border-bottom: 1px solid var(--theme__typeReduced);
}
.neo__picker__teamSelector__item { border: 1px solid transparent; }
.neo__picker__teamSelector__item--active {
  border-color: var(--theme__typeBodie);
  > * { opacity: .6; }
}
.neo__picker__selectedTeam {
  border-bottom: 1px solid var(--theme__typeReduced);
  cursor: pointer;
  display: flex;
  padding: 12px;
  gap: 8px;
  min-height: 48px;
  align-items: center;  
  position: relative;
  > img {
    width: 32px;
    height: auto;
    @include smallUp() { width: 48px; }
    margin-inline-start: -8px;
  }
  > span {
    color: var(--theme__typeReduced);
  }
  &::after {
    content: "";
    width: 20px;
    height: 20px;
    background: center / contain no-repeat;
    @include themedBg("icon__arrowDown");
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translate(0, -50%);
  }
}

.neo__pickerEntry__playerStats {
  > * {
    margin-inline-end: 1.5em;
    &:last-child { margin-inline-end: 0; }
  }
  > span {
    margin-top: -2px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > span {
      @include font__setThin();
      font-size: .7em;
      // color: var(--theme__typeReduced);
    }
    > strong {
      @include font__setNormal();
    }
  }
}