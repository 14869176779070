.neo__fixedCloseIcon__holder {
  position: fixed;
  z-index: 200;
  top: 1.25em;
  right: 1em;
}

.neo__fixedCloseIcon {
  cursor: pointer;
  display: block;
  width: 28px;
  height: 28px;
  background: $dfl__lightGray url("~images/icon__close.png") center / 20px no-repeat;
  border-radius: 14px;
}
