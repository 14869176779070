.neo__story__overlay {
  background: center / cover no-repeat;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  padding: 12px;
  padding-top: calc(2em + 40px);
  display: flex;
  justify-content: stretch;
  align-items: stretch;
}

.neo__story__contentWrapper {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  > * { flex-grow: 1; }
}

.neo__story__content {
  position: relative;
}

.neo__story__closeIcon {
  display: inline-block;
  width: 38px;
  height: 38px;
  background: $dfl__bundesligaRed url("~images/icon__close--dark.png") center / 20px no-repeat;
  border-radius: 18px;
}

.neo__story__closeIcon__holder {
  position: absolute;
  top: 2em;
  right: 2em;
}

.neo__story__userInfoHolder {
  position: absolute;
  top: 1.25em;
  left: 2em;
}
.neo__story__userInfo {
  gap: 8px;
  color: var(--theme__typeHeadline);
  text-shadow: 0 1px 5px rgba(0, 0, 0, .7);
  display: flex;
  align-items: center;
  justify-content: stretch;
  > span:first-child { // avatar
    flex-grow: 0;
    flex-shrink: 0;
    width: 40px;    
  }
  > span:last-child { // name + matchday
    display: inline-block;
    max-width: 120px;
    @include extraSmallOnly() { max-width: 90px; }
    > span:first-child {
      @include font__setStrong();
    }
    > span:last-child {
      font-size: .8em;
    }
  }
}

.neo__story__picks {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

$neo__story__singlePick__height: 54px;
.neo__story__singlePick {
  min-height: $neo__story__singlePick__height;
}

.neo__story__singlePick {
  color: var(--theme__typeHeadline);
  display: flex;
  justify-content: stretch;
  align-items: center;
  gap: 8px;
  .neo__story__singlePick__imageSection { flex-grow: 0; flex-shrink: 0; }
  .neo__story__singlePick__textSection { flex-grow: 1; flex-shrink: 1; }
  .neo__story__singlePick__pointsSection { flex-grow: 0; flex-shrink: 0; }
}
.neo__story__singlePick--scored--wrong {
  opacity: .6;
}
.neo__story__singlePick__imageSection {
  width: $neo__story__singlePick__height;
}
.neo__story__singlePick__textSection {
  display: flex;
  align-items: center;
  line-height: 1em;
  text-shadow: 0 1px 5px rgba(0, 0, 0, .7);
  > div {
    > *:first-child {
      @include font__setStrong();
    }
    > *:nth-child(2) {
      font-size: .8em;
    }
  }
}
.neo__story__singlePick__pointsSection {
  width: 48px;
}
.neo__story__singlePick__placeholderImage {
  width: $neo__story__singlePick__height;
  height: $neo__story__singlePick__height;
  background: $dfl__white;
  opacity: .5;
}
.neo__story__singlePick__playerImageHolder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: $neo__story__singlePick__height;
  height: $neo__story__singlePick__height;
  background: var(--theme__backgroundCardHard);
  img {
    width: $neo__story__singlePick__height;
    height: $neo__story__singlePick__height;
  }
}

.neo__story__actionHolder {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  > * {
    width: 100%;
    max-width: 320px;
  }
}

.neo__story__callToActionHolder {
  @include font__setBlack();
  font-size: 1.4em;
  text-align: center;
  margin: 1.5em 0;
  color: var(--theme__typeHeadline);
}

.neo__story__censoredInfo {
  text-align: center;
  font-size: 1.1em;
  color: $dfl__white;
  line-height: 1.25em;
  text-shadow: 0 1px 3px rgba(0, 0, 0, .3);
  @include font__setStrong();
  padding: 4em 12px;
  background: rgba(0, 0, 0, .5);
}
.neo__story__mdNavi__position {
  margin-top: -3em;
}
.neo__story__mdNavi__holder {
  display: flex;
  justify-content: center;
}
.neo__story__mdNavi {
  display: inline-flex;
  background: rgba(0, 0, 0, .5);
  border-radius: 44px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 12px;  
  color: var(--theme__typeHeadline);
  > strong {
    font-size: 1.2em;
    @include font__setStrong();
  }
  > span.neo__story__mdNavi__prev,
  > span.neo__story__mdNavi__next {
    display: inline-block;
    opacity: .3;
    width: 32px;
    height: 32px;
    background: center / contain no-repeat;
    @include themedBg("icon__arrowDown")
  }
  > span.neo__story__mdNavi__prev {
    transform: rotate(90deg);
  }
  > span.neo__story__mdNavi__next {
    transform: rotate(-90deg);
  }
  > span.neo__story__mdNavi__prev--active,
  > span.neo__story__mdNavi__next--active {
    opacity: 1;
    cursor: pointer;
  }
}

.neo__story__userNavi__holder {
  position: absolute;
  width: calc(100% - 240px);
  max-width: 300px;
  top: 2.25em;
  left: 50%;
  transform: translate(-50%, 0);
  @include extraSmallOnly() {
    transform: translate(calc(-50% + 40px), 0);
  }
  justify-content: stretch;
  align-items: stretch;
  height: 40px;
}
.neo__story__userNavi {
  display: flex;
  flex-grow: 1;
  height: 100%;
  justify-content: stretch;
  gap: 2px;
  > * {
    flex-grow: 1;
    flex-shrink: 0;
  }
}
.neo__story__userNavi__entry {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 8px;
    background-color: var(--theme__backgroundCardHard);
    opacity: .25;
  }
}
.neo__story__userNavi__entry--current {
  &::before { background-color: var(--theme__okType); opacity: 1; }
}