@keyframes __ani__busy__spin {
  from  { transform: rotate(0); }
  to    { transform: rotate(360deg); }
}

@keyframes __ani__busy__in {
  from  { opacity: 0; }
  to    { opacity: .5; }
}

.neo__ui__busyOverlay {
  z-index: 99;
  animation: __ani__busy__in 1.5s;
  animation-fill-mode: both;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  @include theme__light() {
    background: rgba(255, 255, 255, .3);
  }
  @include theme__dark() {
    background: rgba(0, 0, 0, .3);
  }
  display: flex;
  justify-content: center;
  align-items: center;
  > span {
    width: 48px; height: 48px;
    @include smallUp() { width: 60px; height: 60px; }
    background: center / contain no-repeat;
    @include themedBg("icon__loadingSpinner");
    animation: __ani__busy__spin 1.2s linear infinite both;
  }
}
