.neo__picksBody__container {
  color: var(--theme__typeHeadline);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center
}

.neo__picksBody__header {
  > h1 {
    @include font__setBlack;
    display: block;
  }
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.neo__pickBody__bets{
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 20px;
}

.neo__pickBody__bet {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 4em;

  &--wrong {
    opacity: 0.5;
  }
}

.neo__pickBody__bet__pickIcon {
  width: 3em;
  height: 100%;
  background-color: gray;
  @include mediumUp {
    width: 80px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
}

.neo__pickBody__bet__betType {
  @include font__setStrong;
  width: 100%;
  align-self: center;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
.neo__pickBody__bet__betPoints {
  width: 50px;
  @include extraSmallOnly {
    width: 40px;
  }
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.neo__picks__pointsRecieved {
  @include font__setBlack;
  color: var(--theme__typeHeadline);
  margin-top: 20px;
  text-align: center;
}

.neo__pickBody__pointsBG {
  width: 100%;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  @include font__setStrong;
  background: center / contain no-repeat;
  &--predict {
    @include themedBg("pick__points__predict");
  }
  &--correct {
    @include themedBg("pick__points__correct");
  }
  &--wrong {
    @include themedBg("pick__points__wrong");
  }
}

.neo__pickBody__playerIcon {
  aspect-ratio: 1;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: contain;   
  background-position: center;
  background-image: url("images/icon__plus.png");

  &--clickable {
    cursor: pointer;
  }

}