$color__bg: #ffffff;
$color__bg--secondary: #f3f3f6;
$color__txt: #101010;
$color__txt--light: #898989;
$color__primary: #12999B;
$color__lightGrey: #d2d6dd;
$color__grey: #747681;
$color__darkGrey: #3f4144;
$color__error: #d43939;
$color__success: #28bd14;

$font__size: 1.6rem;
$font__familyText: "neo__roboto", "Helvetica Neue", Arial, Helvetica, sans-serif;

$grid__maxWidth: 1280px;
$grid__gutter: 2rem;

:root {
  --color__bg: #{$color__bg};
  --color__bg--secondary: #{$color__bg--secondary};
  --color__txt: #{$color__txt};
  --color__txt--light: #{$color__txt--light};
  --color__primary: #{$color__primary};
  --color__lightGrey: #{$color__lightGrey};
  --color__grey: #{$color__grey};
  --color__darkGrey: #{$color__darkGrey};
  --color__error: #{$color__error};
  --color__success: #{$color__success};

  --font__size: #{$font__size};
  --font__family--sans: #{$font__familyText};

  --grid__maxWidth: #{$grid__maxWidth};
  --grid__gutter: #{$grid__gutter};
}
