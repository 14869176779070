.neo__avatar__wrapper {
  display: flex;
  @include largeUp {
    height: 4em;
  }
  @include extraSmallOnly {
    height: 2em;
  }
  @include smallUp {
    height: 3em;
  }
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
}

.neo__avatar__container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  aspect-ratio: 1 / 1;
  background-color: var(--theme__typeReduced);
  cursor: pointer;
}

.neo__avatarWithHalo__container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  aspect-ratio: 1 / 1;
}

.neo__avatar__image {
  height: 100%;
  aspect-ratio: 1 / 1;
}

.neo__avatar__letter {
  color: var(--theme__typeHeadline);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.neo__avatar__halo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  border: 3px solid $dfl__green;
  padding: 3px;

  &--seen{
    border-color: transparent;
  }
}