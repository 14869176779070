.neo__avatar__carousel__item__wrapper {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
  
  > span {
    @include font__setNormal;
    font-size: small;
  }

  > span:not(.neo__avatar__carousel__matchday__days) {
    margin-top: 10px;
    @include extraSmallOnly {
      margin-top: 5px;
    }
    color: var(--theme__typeReduced);
 
  }
  > span.neo__avatar__carousel__matchday__days {
    color: var(--theme__typeTableHead); 
  }

}

.neo__avatar__carousel__matchday__wrapper{
  @include font__setBlack;
  @include extraSmallOnly {
    font-size: small;
  }
}


.neo__carousel__container {
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding: 0 1em;
}

.neo__carousel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  overflow-x: auto;
  scroll-behavior: smooth;
  width: 100%;
  gap: 15px;
  @include mediumUp{
    gap: 10px;
  }
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
}

.neo__carousel__arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  aspect-ratio: 1/1;
  cursor: pointer;
  height: 100%;
  width: 1em;
  z-index: 2;
  color: var(--theme__backgroundCardReduced);
  
  &:hover {
    color: var(--theme__typeTableHead);
  }
  &--left {
    left: 0;
  }

  &--right{
    right: 0;
  }

} 