.neo__ui__row {
  margin: 24px 0;
  @include smallUp() { margin: 32px 0; }
}

.neo__icon__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  > .neo__icon {
    width: 100%;
    height: auto;
    &--up {
     color: var($dfl__green);
    }
    &--down {
     color: $dfl__red
    }
  }
}

.neo__separator__line {
  content: "";
  height: 2px;
  background-color: var(--theme__typeReduced);
}

.neo__separator__line--vertical {
  content: "";
  width: 2px;
  height: 1em;
  background-color: var(--theme__typeReduced);
}

.neo__button {
  --neo__button__bg: var(--theme__buttonPrimaryBackground);
  --neo__button__border: var(--theme__buttonPrimaryBackground);
  --neo__button__type: var(--theme__buttonPrimaryType);
  --neo__button__bg--hover: var(--theme__buttonPrimaryType);
  --neo__button__border--hover: var(--theme__buttonPrimaryBackground);
  --neo__button__type--hover: var(--theme__buttonPrimaryBackground);
  background: var(--neo__button__bg);
  border: 1px solid var(--neo__button__border);
  &, &:hover, &:visited { color: var(--neo__button__type); text-decoration: none; }
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: 44px;
  padding: 4px 12px;
  text-align: center;
  min-width: 180px;
}

.neo__button--secondary {
  --neo__button__bg: var(--theme__buttonPrimaryType);
  --neo__button__border: var(--theme__buttonSecondaryBackground);
  --neo__button__type: var(--theme__buttonSecondaryType);
  --neo__button__bg--hover: var(--theme__buttonPrimaryBackground);
  --neo__button__border--hover: var(--theme__buttonPrimaryBackground);
  --neo__button__type--hover: var(--theme__buttonPrimaryType);
}

a.neo__button {
  cursor: pointer;
  &:hover { // using manual hover-class (see below) to prevent staying in hover state too long
    // background: var(--neo__button__bg--hover);
    // border: 1px solid var(--neo__button__border--hover);
    // color: var(--neo__button__type--hover);
  }
}
a.neo__button--hovered {
  background: var(--neo__button__bg--hover);
  border: 1px solid var(--neo__button__border--hover);
  color: var(--neo__button__type--hover);
  &, &:hover, &:visited { color: var(--neo__button__type--hover);}
}

span.neo__button {
  cursor: not-allowed;
  opacity: .3;
}


.neo__ui__panel {
  position: relative;
}
.neo__ui__panel--card {
  background: var(--theme__backgroundCardHard);
  padding: 12px;
  padding-top: 1px;
  padding-bottom: 1px;

  @include extraSmallOnly() { padding-inline-start: 8px; padding-inline-end: 8px; }
}
.neo__ui__panel--transparent {

}
.neo__ui__panel--fullContent { padding: 0; position: relative; }
.neo__ui__panel--wider {
  width: 500px;
  max-width: 100%;
  margin-inline-start: auto;
  margin-inline-end: auto;
}
.neo__ui__panel--slim {
  width: 420px;
  max-width: 100%;
  margin-inline-start: auto;
  margin-inline-end: auto;
}
.neo__ui__panel--full {
  max-width: 100%;
  margin-inline-start: auto;
  margin-inline-end: auto;
}

.neo__ui__pageWrapper {
  position: relative;
}

.neo__ui__section { padding: .1px 12px; position: relative; }
.neo__ui__section--fullWidth { padding: .1px 0; }
.neo__ui__section--hard { background: var(--theme__backgroundCardHard); }
.neo__ui__section--reduced { background: var(--theme__backgroundCardReduced); }
.neo__ui__section--transparent {}

.neo__ui__row { margin: 18px 0; }

h3.neo__ui__sectionTitle {
  margin: 0;
  text-align: center;
  font-size: 1.2em;
  @include font__setBlack();
}

h2.neo__ui__pageTitle {
  margin: 0;
  margin-bottom: 2em;
  text-align: center;
  font-size: 1.8em;
  @include extraSmallOnly() { font-size: 1.6em; }
  @include font__setBlack();
}
