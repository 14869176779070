@use "sass:math";

$sandbox__accentCol: aquamarine;
$sandbox__txt: var(--theme__typeBodie);
$sandbox__txtLight: var(--theme__typeBodie);
$sandbox__todoCol: mediumvioletred;

.sandbox__fullPageWrapper {
  padding-bottom: 4em;
}

.sandbox__devLink {
  position: absolute;
  font-size: .8em;
  padding: 2px 6px;
  top: 0;
  right: 20px;
  &, &:hover, &:active {
    color: black;
    text-decoration: none;
  }
  background: $sandbox__accentCol;
  > span {
    display: none;
  }
  &:hover > span { display: unset; margin-inline-end: 4px; }
}

.sandbox__block--slim {
  position: relative;
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
}

.sandbox__block--framed {
  border: 4px solid purple;
}

.sandbox__pageContent {
  padding: 0 12px;
}

.sandbox__navi {
  padding-left: 12px;  
  display: flex;
  flex-wrap: wrap;
  margin: .75em 0;
  > * {
    margin: .25em 1em;
    margin-inline-start: 0;
  }
}

a.sandbox__navi__link {
  cursor: pointer;
  color: darken($sandbox__accentCol, 30%);
  &:visited, &:hover {
    color: darken($sandbox__accentCol, 30%);
  }
  &.--active {
    text-decoration: underline;    
  }
}

h1.sandbox__pageTitle {
  color: $sandbox__txt;
  font-weight: bold;
  text-align: center;
  font-size: 2em;
  border-bottom: 8px solid $sandbox__accentCol;
  padding: 1em 0;
  position: relative;
  @include smallUp {
    margin: 0 2em 1em 2em;
  }
  @include extraSmallOnly {
    font-size: 1.25em;
  }
}

h2.sandbox__sectionTitle {
  color: $sandbox__txtLight;
  position: relative;
  @include font__setThin();
  text-align: center;
  font-size: 2em;
  > span {
    &::before {
      content: "";
      background: $sandbox__accentCol;
      width: 4px;
      height: 1.15em; // line height of text
      position: absolute;
      left: -12px;
    }
    border-bottom: 1px solid $sandbox__txtLight;
  }
  
  padding: 1em 0;
  margin: 0;
  @include smallUp {
    margin-bottom: 1em;
  }
  @include extraSmallOnly {
    font-size: 1.25em;
    margin-bottom: .5em;
  }
}

h2.sandbox__blockTitle {
  font-size: 28px;
  font-family: monospace;
  &::after {
    content: " " attr(title);
    font-weight: 100;
    font-size: .6em;
    color: $sandbox__txtLight;
  }
}

h2.sandbox__blockTitle::before {
  content: "";
  position: absolute;
  width: 1em;
  height: 1em;
  margin-inline-start: calc(-1em - 4px);
  background: $sandbox__accentCol;
}

.sandbox__grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
  align-items: stretch;
  > * {
    margin: 4px;
    position: relative;
  }
}
@for $i from 1 to 12 {
  .sandbox__grid--desktop#{$i} { > * { @include smallUp { width: calc(#{math.div(100%, $i)} - 8px) } } }
  .sandbox__grid--phone#{$i} { > * { @include extraSmallOnly { width: calc(#{math.div(100%, $i)} - 8px) } } }
}

.sandbox__note {
  padding: .1em 1em;
  border-left: 4px solid $sandbox__accentCol;
  font-style: italic;
  margin: 1em 3em;
  &::before {
    content: "";
    font-style: normal;
    color: $sandbox__accentCol;
    font-size: 2em;
    font-weight: bold;
    position: absolute;
    margin-inline-start: -1.5em;
  }
  ul {
    padding-inline-start: 1em;
    list-style-type: square;
  }
}

.sandbox__todo {
  padding: .1em 1em;
  border-left: 4px solid $sandbox__todoCol;
  font-style: italic;
  margin: 1em 3em;
  &::before {
    content: "TODO";
    font-style: italic;
    color: $sandbox__todoCol;
    font-size: 2em;
    font-weight: bold;
    position: absolute;
    transform: rotate(90deg);
    margin-inline-start: -2.5em;
  }
  ul {
    padding-inline-start: 1em;
    list-style-type: square;
  }
}
