$dfl__bundesligaRed: #d20515;
$dfl__premiumBlack: #000514;
$dfl__hardBlack: #191e24;
$dfl__softBlack: #34383c;
$dfl__darkGray: #64686c;
$dfl__mediumGray: #888c90;
$dfl__lightGray: #c9cccf;
$dfl__superLightGray: #e9ebed;
$dfl__offWhite: #f4f5f6;
$dfl__white: #ffffff;

$dfl__green: #118715;
$dfl__greenLight: #1fc779;
$dfl__red: #ea0b18;
$dfl__redLight: #f42531;
$dfl__yellow: #fcce00;
