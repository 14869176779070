.neo__matchday__header__holder {
  border-bottom: 1px solid var(--theme__dividerDarkReduced);
}

.neo__matchday__header {
  min-height: 80px;
  padding: 12px;
  padding-inline-end: 0;
  display: flex;
  justify-content: stretch;
  align-items: center;
  gap: 12px;

  .neo__matchday__header__titleSection {
    flex-shrink: 0;
    flex-grow: 0;
    > * { white-space: nowrap; }
    h2, h3 {
      margin: .2em 0;
    }
    h2 {
      font-size: 1em;
      @include font__setStrong();      
    }
    h3 {
      font-size: 1em;
      @include font__setThin();
    }
  }

  .neo__matchday__header__sideSection {
    flex-grow: 1;
  }
}

.neo__matchday__header__sideSection {
  overflow-y: hidden;
  user-select: none;
}

.neo__matchday__header__sideSection__inner {
  display: flex;
  gap: 4px;

  > div {
    width: 58px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    position: relative;
    > span:first-child { // avatar

    }
    > span:last-child { // text
      font-size: .8em;
      @include font__setThin();
      width: 100%;
      flex-grow: 0;
      flex-shrink: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.neo__matchday__countdown__placeholderWhileLoadingHolder {
  opacity: .15;
}

.neo__matchday__countdown {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
}

.neo__matchday__countdown__separator {
  @include font__setBlack();
  font-size: 1.8em;
}

.neo__matchday__countdown__val {
  flex-shrink: 0;
  > strong { // num
    @include font__setBlack();
    font-size: 1.8em;
  }
  > span { // day label
    @include font__setThin();
    font-size: .8em;
  }
}

.neo__matchday__countdown__val--d {
  margin-inline-end: 10px;
  > *:first-child { margin-inline-end: 4px; }
}

.neo__matchday__countdownHolder {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4px;
  > div:first-child {
    @include font__setThin();
    font-size: .8em;
    text-align: center;
  }
}

.neo__matchday__actionHolder {
  margin: 2em 0;
  display: flex;
  justify-content: center;
  > * {
    width: calc(100% - 16px);
    max-width: 320px;
  }
}

.neo__matchday__actionTextinfoHolder {
  text-align: center;
  > p { margin: 0; }
  > a {
    &, &:hover, &:active { color: var(--theme__typeHeadline); }
    display: inline-block;
    text-transform: uppercase;
    text-decoration: none;
    @include font__setStrong();
    margin-top: .5em;
  }
}

.neo__matchday__scoredInfoHolder {
  padding: 8px;
  text-align: center;
  width: 80%;
  margin: 0 auto;
}

.neo__matchday__seasonPointsInfoHolder {
  text-align: center;
  font-size: 1.2em;
  @include font__setBlack();
}

.neo__saveResult__holder {
  position: relative;
  height: 0;
}
@keyframes __ani__neoSaveResultInAndOut {
  0%    { opacity: 0; }
  10%   { opacity: 1; }
  90%   { opacity: 1; }
  100%  { opacity: 0; }
}
.neo__saveResult {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  animation: __ani__neoSaveResultInAndOut 2s both;
  > * {
    display: inline-block;
    border: 1px solid var(--theme__typeBodie);
    padding: 4px 8px;
    text-align: center;
    max-width: 300px;
  }
}

.neo__matchday__nextOpenInSec {
  > * {
    margin: 0;
  }
  strong {
    @include font__setStrong();
  }
  > p { // info
    text-align: center;
  }
}
