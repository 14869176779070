@keyframes __ani__ui__errorOverlay__allIn {
  from  { opacity: 0; }
  to    { opacity: 1; }
}

@keyframes __ani__ui__errorOverlay__popupIn {
  from  { opacity: .8; transform: scale(.8); }
  to    { opacity: 1; transform: scale(1); }
}

@mixin neo__errorBoxStyle {
  background: var(--theme__backgroundCardHard);
  border: 1px solid var(--theme__errorType);
  box-shadow: 0 3px 6px rgba(0, 0, 0, .3);
  padding: 18px;
  @include extraSmallOnly() { padding: 12px; }
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  gap: 12px;
  > * { margin: 0; }
}

.neo__ui__displayError__overlay {
  z-index: 99;
  animation: __ani__ui__errorOverlay__allIn .5s;
  animation-fill-mode: both;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  @include theme__light() {
    background: rgba(255, 255, 255, .5);
  }
  @include theme__dark() {
    background: rgba(0, 0, 0, .4);
  }
  display: flex;
  justify-content: center;
  align-items: center;
  > * {
    animation: __ani__ui__errorOverlay__popupIn .5s;
    width: 80%;
    @include neo__errorBoxStyle();
    max-width: 340px;
    min-height: 120px;
  }
  a {
    &, &:hover, &:visited {
      color: var(--theme__typeHeadline);
      padding: 1px 12px;
      border: 1px solid var(--theme__typeHeadline);
    }
  }
}
