.neo__nav__container { 
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--theme__backgroundCardHard);
}

.neo__nav__wrapper {
  min-height: 2em;
  line-height: 2em;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  flex-wrap: nowrap;
  @include extraSmallOnly {
    flex-wrap: wrap;
  }
}

.neo__nav__tab {
  @include font__setBlack;
  cursor: pointer;
  position: relative;
  flex: 1;
  text-align: center;
  color: var(--theme__typeReduced);
  font-size: var(--font__size);
  @include extraSmallOnly {
    padding: 2px 2px;
  }
  &--disabled {
    cursor: default;
  }
  
  
  &:hover:not(neo__nav__tab--disabled) {
    background-color: var(--theme__dividerDarkReduced);
  }
  
  &--active {
    color: var(--theme__typeHeadline);
    border-bottom: 2px solid var(--theme__typeHeadline);
  }

  > span {
    text-transform: uppercase;
  }
}
