$neo__bet__height: 54px;

.neo__bets {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.neo__bet,
.neo__betPlaceholder {
  min-height: $neo__bet__height;
}

@keyframes __ani__betPlaceholder__fade {
  from  { opacity: .8}
  to    { opacity: .2}
}

.neo__betPlaceholder {
  background-color: var(--theme__dividerDarkReduced);
  animation: __ani__betPlaceholder__fade 1s both alternate infinite;
  &:nth-child(2) { animation-delay: .2s; }
  &:nth-child(3) { animation-delay: .4s; }
  &:nth-child(4) { animation-delay: .6s; }
  &:nth-child(5) { animation-delay: .8s; }
}

.neo__bet {
  display: flex;
  justify-content: stretch;
  align-items: center;
  gap: 8px;
  .neo__bet__imageSection { flex-grow: 0; flex-shrink: 0; }
  .neo__bet__textSection { flex-grow: 1; flex-shrink: 1; }
  .neo__bet__pointsSection { flex-grow: 0; flex-shrink: 0; }
}

.neo__bet--enabled {
  cursor: pointer;
}

.neo__bet--disabled {
  cursor: not-allowed;
}

.neo__bet--notScored {
}

.neo__bet--scored {
}

.neo__bet--scored--correct {}

.neo__bet--scored--wrong {
  opacity: .6;
}

.neo__bet__imageSection {
  width: $neo__bet__height;
}
.neo__bet__textSection {
  display: flex;
  align-items: center;
  line-height: 1em;
  > div {
    > *:first-child {
      @include font__setStrong();
    }
    > *:nth-child(2) { // player name
      color: var(--theme__darkGrayText);
      font-size: .8em;
    }
  }
}
.neo__bet__pointsSection {
  width: 48px;
}
.neo__bet__pickIcon {
  width: $neo__bet__height;
  height: $neo__bet__height;
  background: var(--theme__backgroundAboveCard) center / 16px no-repeat;
  @include themedBg("icon__plus");
}
.neo__bet__placeholderImage {
  width: $neo__bet__height;
  height: $neo__bet__height;
  background: var(--theme__backgroundAboveCard) center / 12px no-repeat;
  @include themedBg("icon__close");
}
.neo__bet__playerImageHolder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: $neo__bet__height;
  height: $neo__bet__height;
  background: var(--theme__backgroundCardHard);
  img {
    width: $neo__bet__height;
    height: $neo__bet__height;
  }
}
.neo__bet--enabled .neo__bet__playerImageHolder { box-shadow: 0 2px 6px rgba(0, 0, 0, .2); }

.neo__bet__points {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: $neo__bet__height;
  background: center / contain no-repeat;
  strong {
    @include font__setBlack();
    font-size: .95em;
    padding-bottom: .25em;
    color: var(--theme__typeHeadline);
  }
}
.neo__bet__points--default {
  @include themedBg("pick__points__predict")
}
.neo__bet__points--correct {
  @include themedBg("pick__points__correct")
}
.neo__bet__points--wrong {
  @include themedBg("pick__points__wrong");
  strong { opacity: .5; }
}

.neo__bet__playerName {
  white-space: nowrap;
  text-overflow: ellipsis;
  > span { @include font__setThin(); }
  > strong { @include font__setStrong(); }
}
