$theme__footerBg: #EBEBEB;
$theme__footerBorder: #D4D4D4;
$theme__footerTxt: #0A2D52;
$theme__headerBg: #1c4759;
$theme__headerGradient: linear-gradient(#254658, #346e72);
$theme__titleUnderlineGradient: linear-gradient(90deg, #CDEBFD, #BFFDF5);
$theme__minHeightToDisplayHeader: 700px;
$theme__minHeightHaveHeaderAndFooterMargin: 750px;
$theme__headerAndFooterMargin: 20px;
$theme__buttonGradient: linear-gradient(#51a6a9, #458e94);
$theme__buttonBorder: #408295;
$theme__buttonGradient--hover: linear-gradient(#60c1c4, #53a8ae);
$theme__buttonBorder--hover: #4b96af;
$theme__txt: #000000;
$theme__link: #14A6A8;
$theme__txt--light: #6F6D6D;

.neo__theme {
  h1, h2, h3, h4 { color: var(--theme__typeHeadline); }
  color: var(--theme__typeBodie);
}

@mixin themeColor($varName, $light, $dark) {
  :root,
  .neo__theme--light {
    #{$varName}: #{$light};
  }  
  .neo__theme--dark {
    #{$varName}: #{$dark};
  }  
}

@mixin theme__light {
  @at-root .neo__theme--light & {
    @content;
  }
}

@mixin theme__dark {
  @at-root .neo__theme--dark & {
    @content;
  }
}

.neo__theme.neo__theme__withBackground { background: var(--theme__backgroundSiteLowContrast); }

@mixin themedBg ($imageName, $ext: "png") {
  @at-root .neo__theme--light & {
    background-image: url("~@images/#{$imageName}.#{$ext}");
  }
  @at-root .neo__theme--dark & {
    background-image: url("~@images/#{$imageName}--dark.#{$ext}");
  }
}

@include themeColor(
  --theme__backgroundSiteLowContrast,
  $light: $dfl__offWhite,
  $dark: $dfl__premiumBlack
);

@include themeColor(
  --theme__backgroundCardHard,
  $light: $dfl__white,
  $dark: $dfl__hardBlack
);

@include themeColor(
  --theme__typeBodie,
  $light: $dfl__softBlack,
  $dark: $dfl__lightGray
);

@include themeColor(
  --theme__backgroundCardReduced,
  $light: $dfl__offWhite,
  $dark: $dfl__hardBlack
);

@include themeColor(
  --theme__typeTableHead,
  $light: $dfl__softBlack,
  $dark: $dfl__mediumGray
);

@include themeColor(
  --theme__typeReduced,
  $light: $dfl__lightGray,
  $dark: $dfl__darkGray
);

@include themeColor(
  --theme__typeHeadline,
  $light: $dfl__premiumBlack,
  $dark: $dfl__white
);

@include themeColor(
  --theme__dividerDarkReduced,
  $light: $dfl__superLightGray,
  $dark: $dfl__premiumBlack
);

@include themeColor(
  --theme__buttonPrimaryBackground,
  $light: $dfl__premiumBlack,
  $dark: $dfl__white
);

@include themeColor(
  --theme__errorType, // selfmade color
  $light: $dfl__red,
  $dark: $dfl__redLight
);

@include themeColor(
  --theme__darkGrayText, // selfmade color
  $light: $dfl__darkGray,
  $dark: $dfl__lightGray
);

@include themeColor(
  --theme__okType, // selfmade color
  $light: $dfl__green,
  $dark: $dfl__greenLight
);

@include themeColor(
  --theme__backgroundAboveCard, // selfmade color
  $light: $dfl__superLightGray,
  $dark: $dfl__softBlack
);

@include themeColor(
  --theme__buttonPrimaryType,
  $light: $dfl__white,
  $dark: $dfl__premiumBlack
);


@include themeColor(
  --theme__buttonSecondaryBackground,
  $light: $dfl__premiumBlack,
  $dark: $dfl__white
);


@include themeColor(
  --theme__buttonSecondaryType,
  $light: $dfl__premiumBlack,
  $dark: $dfl__white
);

