.neo__complPro__dropdown {
  border-bottom: 1px solid var(--theme__typeReduced);
  cursor: pointer;
  display: flex;
  padding: 12px;
  gap: 8px;
  min-height: 48px;
  align-items: center;  
  position: relative;
  > span {
    color: var(--theme__typeReduced);
  }
  &::after {
    content: "";
    width: 20px;
    height: 20px;
    background: center / contain no-repeat;
    @include themedBg("icon__arrowDown");
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translate(0, -50%);
  }
}

input.neo__complPro__input {
  width: 100%;
  border: none;
  outline: none;
  border-radius: none;
  background: transparent;
  &::placeholder {
    color: var(--theme__typeReduced);
    opacity: 1;
  }
  &:focus {
    background: var(--theme__backgroundCardHard);
  }
  border-bottom: 1px solid var(--theme__typeReduced);
  padding: 12px;
  min-height: 48px;
  align-items: center;  
  position: relative;
  color: var(--theme__typeHeadline);
}
.neo__complPro__input__cancelIcon {
  cursor: pointer;
  z-index: 1;
  width: 20px;
  height: 20px;
  background: center / contain no-repeat;
  @include themedBg("icon__close");
  position: absolute;
  right: 12px;
  bottom: 4px;
  transform: translate(0, -50%);
}
.neo__complPro__input__holder {
  position: relative;
}
.neo__complPro__input__label {  
  font-size: .8em;
  padding: 4px 12px;
  @include font__setThin();
  color: var(--theme__typeReduced);
}
.neo__complPro__input__label--hide {
  visibility: hidden;
}
.neo__complPro__actionHolder {
  margin-top: 3em;
  flex-direction: column;
  gap: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  > * {
    width: 300px;
    max-width: 100%;
  }
}
.neo__complPro__errorHolder {
  color: var(--theme__errorType);
  text-align: center;
}
.neo__complPro__selectedAvatar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  margin-top: 40px;
  > * {
    width: 50%;
    max-width: 160px;
    height: auto;
  }
}
.neo__complPro__chooseAvatar {
  position: relative;
  display: flex;
  align-items: stretch;
  gap: 4px;
  flex-wrap: wrap;
  margin-top: 8px;
  > * {
    width: calc(100% / 6 - 4px * 5 / 6);
    flex-grow: 0;
    flex-shrink: 0;
  }
}
.neo__complPro__chooseAvatar__item {
  cursor: pointer;
  position: relative;  
  > * {
    width: 100%;
    height: auto;
  }
}
.neo__complPro__chooseAvatar__item--selected {
  opacity: .25;
}