@import "normalize.css";
@import 'react-indiana-drag-scroll/dist/style.css';

@import "@style/_ci--dfl";
@import "@style/_defaults";
@import "@style/_theme";
@import "@style/_fonts";
@import "@style/_typography";
@import "@style/_base";
@import "@style/_media_queries";
@import "@style/_helper";
@import "@style/_transitions";

html, body {
  padding: 0;
  margin: 0;
  outline: 0;
  position: relative;
  height: 100%;
  min-height: 100%;
}

@import "@style/layout/_main";
@import "@style/layout/_html";
@import "@style/layout/_grid";
@import "@style/layout/_body";
@import "@style/layout/_footer";
@import "@style/layout/_applinks";
@import "@style/layout/_header";
@import "@style/components/_ui";
@import "@style/components/_ranking";
@import "@style/components/_avatar";
@import "@style/components/_carousel";
@import "@style/components/_ranking_item";
@import "@style/components/_dropdown";
@import "@style/components/_leaderboard_navbar";
@import "@style/components/_ranking_header";
@import "@style/components/_picks";
@import "@style/components/_landing";
@import "@style/components/_complete_profile";
@import "@style/components/_matchday";
@import "@style/components/_tiny_pagination";
@import "@style/components/_header";
@import "@style/components/_busy_overlay";
@import "@style/components/_fixed_close_icon";
@import "@style/components/_popup";
@import "@style/components/_display_error_overlay";
@import "@style/components/_picker";
@import "@style/components/_bets";
@import "@style/components/_buddy";
@import "@style/components/_buddy_search";
@import "@style/components/_story";

@import "@style/pages/_sandbox";

.indiana-scroll-container--hide-scrollbars {
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
