@keyframes __ani__leaderboard__horizontalFlicker {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@mixin neoLoader {
  content: "";
  background-color: var(--theme__dividerDarkReduced);
  animation: __ani__leaderboard__horizontalFlicker 1s ease-in-out infinite;
  display: inline-block;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  height: 2em;
}

.neo__loading {
  @include neoLoader;
  flex-grow: 1;
}
.neo__ranking__avatar__wrapper {
  display: flex;
  height: 70%;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.neo__ranking__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 3em;
  gap: 20px;
  @include extraSmallOnly {
    gap: 5px;
  }
  color: var(--theme__typeHeadline);
  &--loading {
    @include neoLoader;
  }
}

.neo__ranking__name__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  flex-grow: 1;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
  &--loading {
    flex-grow: 1;
    content: "";
    background-color: var(--theme__dividerDarkReduced);
    width: 100%;
    height: 100%;
    animation: __ani__betPlaceholder__fade 1s both alternate infinite;
  }
  > span {
    display: inline-block;
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.neo__ranking__points__wrapper {
  width: 100px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  @include font__setStrong;
  @include font__setBlack;
  @include extraSmallOnly {
    width: 50px;
  }
}

.neo__ranking__vertical__indicator {
  content: "";
  display: flex;
  width: 5px;
  align-self: stretch;
  &--filled {
    background-color: var(--theme__typeHeadline);
  }
}

.neo__ranking__position {
  width: 50px;
  @include extraSmallOnly {
    width: auto;
  }
  text-align: center;
}

.neo__ranking__avatar {
  height: 100%;
  aspect-ratio: 1/1;
  color: var(--theme__typeHeadline);
}

.neo__ranking__name__icon__wrapper {
  width: 20px;
  height: auto;
  content: "";
}

.neo__ranking__dropdown__container{
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  gap: 10px;
  > * { flex-grow: 1; width: 100%; }
}