.neo__landing__actionHolder {
  display: flex;
  justify-content: center;
  align-items: center;
}

.neo__landing__intro {
  text-align: center;
  @include font__setStrong();
  color: var(--theme__typeHeadline);
}

.neo__landingTeaser {
  position: relative;
  overflow: hidden;
  > * {
    overflow-y: hidden;
  }
  > .indiana-scroll-container { overflow-y: hidden; }
}

.neo__landingTeaser__inner {
  padding: 0 20px;
  @include smallUp() { padding: 0 40px; }
  display: flex;
  gap: 4px;
  > div {
    user-select: none;
    flex-shrink: 0;
    width: calc(100%);
    img { width: 100%; height: auto; }
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    gap: 8px;
  }
  &::after {
    content: "";
    flex-grow: 0;
    flex-shrink: 0;
    width: 20px;
    @include smallUp() { width: 40px; }
  }
}

.neo__landing__countdownHolder {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;

  > h3 {
    @include font__setStrong();
    margin: 0;
    font-size: 1em;
    text-align: center;
  }
  > div:nth-child(2) {
    font-size: .8em;
    @include font__setThin();
    text-align: center;
  }
}
