// DFLRegular from template

@font-face {
  font-family: DFLCondensedRegular;
  src: url("@style/fonts/DFLCn-Rg2.woff") format("woff");
  src: url("@style/fonts/DFLCn-Rg2.woff2") format("woff2");
}
@font-face {
  font-family: DFLCondensedBold;
  src: url("@style/fonts/DFLCn-Bd2.woff") format("woff");
  src: url("@style/fonts/DFLCn-Bd2.woff2") format("woff2");
}
@font-face {
  font-family: DFLDisplayBlack;
  src: url("@style/fonts/DFLDisplay-Bl2.woff") format("woff");
  src: url("@style/fonts/DFLDisplay-Bl2.woff2") format("woff2");
}

// Mixins

@mixin font__setNormal() {
  font-family: DFLWeb,DFLCondensedRegular,Arial,Helvetica,sans-serif;
  font-weight: normal;
}
@mixin font__setThin() {
  font-family: DFLCondensedRegular,Arial,Helvetica,sans-serif;
  font-weight: normal;
}
@mixin font__setStrong() {
  font-family: DFLCondensedBold,Arial,Helvetica,sans-serif;
  font-weight: bold;
}
@mixin font__setBlack() {
  font-family: DFLDisplayBlack,Arial,Helvetica,sans-serif;
  font-weight: bold;
}
