.neo__rankingHeader__wraper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}
  
.neo__closeHeader__wraper {
  content: "";
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  right: 0;
  top: 0;
  height: 1em;
  aspect-ratio: 1 / 1;
}

.neo__mainEmblem__wraper {
  margin-top: 0.5rem;
  width: 500px;
  @include extraSmallOnly{
    width: 60%;
  }
  @include smallOnly {
    width: 300px;
  }
  @include mediumUp{
    width: 350px;
  }
}

.neo__team__wraper {
  @include font__setBlack;
  color: var(--theme__typeHeadline);
  margin: 1em 0;
  @include extraSmallOnly {
    margin: 5px 0;
  } 
}

.neo__rankingStats__wraper {
  @include font__setThin;
  font-size: 1em;
  width: 100%;
  line-height: 2em;
  padding: 0 10px;
  line-height: 2em;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  color: var(--theme__typeReduced);
  @include extraSmallOnly {
    padding: 0;
    flex-wrap: wrap;
  }
}

.neo__statIcon__wraper {
  position: relative;
  width: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;

}
.neo__leaderboard__stat {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}