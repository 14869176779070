.neo__dropdown__container {
  display: flex;
  justify-content: stretch;
  position: relative;
  border-bottom: 1px solid var(--theme__typeHeadline); 
}

.neo__dropdown__select {
  appearance: none;
  margin-top: 0.5em;
  color: var(--theme__typeHeadline);
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  background-color: transparent;
  @include font__setNormal;
  border: none; 
  flex: 1;
  box-sizing: border-box;
  @include extraSmallOnly {

  }
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

.neo__dropdown__icon__container {
  position: absolute;
  width: 30px;
  height: 100%;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.neo__dropdown__icon {
  background: center / contain no-repeat;
  @include themedBg("icon__arrowDown");
  width: 20px;
  height: 20px;
}